import _ from 'lodash';
export class ContratoModel {
  constructor({
    id,
    codigo,
    participanteId,
    participanteContatoId,
    contratoAnteriorId,
    status = 'EmAvaliacao',
    numeroParcela,
    dataInicio,
    dataRenovacao,
    dataTermino,
    dataAssinatura,
    tipoContrato,
    dataEnvio,
    observacao,
    nomeTestemunha,
    cpfTestemunha,
    valorMensal,
    valorFinal,
    valorItens,
    valorDesconto = 0,
    tipoAcrescimo = 'Percentual',
    valorAcrescimo = 0,
    tipoDesconto = 'Percentual',
    flagRenovacao,
    flagAditivo,
    participanteContaId,
    participanteLaboratorioTerceiroId,
    contratoInstrumento,
    contratoHistoricoModificacao,
    contratoClausulasContratuais = [],
    contratoServicoManutencao = [],
    contratoPeca = [],
    politicaPrecoPeca,
  }) {
    this.id = id;
    this.codigo = codigo;
    this.participanteId = participanteId;
    this.participanteContatoId = participanteContatoId;
    this.contratoAnteriorId = contratoAnteriorId;
    this.status = status;
    this.numeroParcela = numeroParcela;
    this.dataInicio = dataInicio;
    this.dataRenovacao = dataRenovacao;
    this.dataTermino = dataTermino;
    this.dataAssinatura = dataAssinatura;
    this.tipoContrato = tipoContrato;
    this.dataEnvio = dataEnvio;
    this.observacao = observacao;
    this.nomeTestemunha = nomeTestemunha;
    this.cpfTestemunha = cpfTestemunha;
    this.valorMensal = valorMensal;
    this.valorFinal = valorFinal;
    this.valorItens = valorItens;
    this.valorDesconto = valorDesconto;
    this.tipoAcrescimo = tipoAcrescimo;
    this.valorAcrescimo = valorAcrescimo;
    this.tipoDesconto = tipoDesconto;
    this.flagRenovacao = flagRenovacao;
    this.flagAditivo = flagAditivo;
    this.participanteContaId = participanteContaId;
    this.participanteLaboratorioTerceiroId = participanteLaboratorioTerceiroId;
    this.contratoInstrumento = contratoInstrumento;
    this.contratoHistoricoModificacao = contratoHistoricoModificacao;
    this.contratoClausulasContratuais = contratoClausulasContratuais;
    this.contratoServicoManutencao = contratoServicoManutencao;
    this.contratoPeca = contratoPeca;
    this.politicaPrecoPeca = politicaPrecoPeca;

    if (this.tipoContrato == 'ContratoAberto') this.numeroParcela = 0;
  }
  get request() {
    let retorno = _.cloneDeep(this);

    if (retorno.tipoContrato == 'ContratoAberto') retorno.numeroParcela = 0;

    return retorno;
  }
}
