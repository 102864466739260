<template>
  <div>
    <div class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ $t('modulos.contrato.assinar_contrato') }}
      </div>
    </div>
    <v-tabs
      v-model="indiceAba"
      class="abas-padrao"
      background-color="primary"
      color="primary"
    >
      <v-tab>
        {{ $t('modulos.contrato.formulario.abas.dados_contrato') }}
      </v-tab>
      <v-tab>
        {{ $t('modulos.contrato.formulario.abas.escopo_contrato') }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="indiceAba">
      <v-tab-item>
        <v-form ref="form">
          <div class="row mt-2">
            <input-text
              v-model="form.codigo"
              class="col-12 col-md-3"
              :label="$t('modulos.contrato.formulario.codigo')"
              disabled
            />
            <input-cliente
              v-model="form.participante"
              class="col-12 col-md-5"
              disabled
              :label="$t('modulos.contrato.formulario.cliente')"
            />
            <input-select
              v-model="form.tipoContrato"
              class="col-12 col-md-4"
              :label="$t('modulos.contrato.formulario.tipo_contrato')"
              disabled
              :options="opcoes.tipoContrato"
            />
          </div>
          <div class="row mt-2">
            <input-contrato
              v-model="form.contratoAnterior"
              class="col-12 col-md-3"
              :label="$t('modulos.contrato.formulario.renovacao_contrato')"
              disabled
            />
            <input-select
              v-model="form.status"
              class="col-12 col-md-4"
              :label="$t('modulos.contrato.formulario.situacao')"
              :options="opcoes.situacaoContrato"
              disabled
            />
            <input-date
              v-model="form.dataInicio"
              class="col-12 col-md-2"
              :label="$t('modulos.contrato.formulario.data_inicio')"
              disabled
            />
            <input-date
              v-model="form.dataRenovacao"
              class="col-12 col-md-2"
              :label="$t('modulos.contrato.formulario.data_renovacao')"
              disabled
            />
            <input-text
              v-model="form.numeroParcela"
              class="col-12 col-md-1"
              :label="$t('modulos.contrato.formulario.parcelas')"
              type="number"
              disabled
            />
          </div>
          <div class="row mt-2">
            <input-money
              v-model="form.valorAcrescimo"
              class="col-12 col-md-2"
              :label="$t('modulos.contrato.formulario.acrescimo')"
              type="number"
              disabled
              :options="optionsInputMoney"
            />
            <input-money
              v-model="form.valorDesconto"
              class="col-12 col-md-2"
              :label="$t('modulos.contrato.formulario.desconto')"
              type="number"
              disabled
              :options="optionsInputMoney"
            />
            <input-money
              v-model="form.valorItens"
              class="col-12 col-md-2"
              :label="$t('modulos.contrato.formulario.valor_itens')"
              type="number"
              :options="optionsInputMoney"
              disabled
            />
            <input-money
              v-model="form.valorFinal"
              class="col-12 col-md-4"
              :label="$t('modulos.contrato.formulario.valor_final')"
              type="number"
              disabled
              :options="optionsInputMoney"
            />
            <input-money
              v-model="form.valorMensal"
              class="col-12 col-md-2"
              :label="$t('modulos.contrato.formulario.valor_mensal')"
              type="number"
              disabled
              :options="optionsInputMoney"
            />
          </div>
        </v-form>
      </v-tab-item>
      <v-tab-item>
        <tabela-padrao-prime-vue
          v-model="tabela.selecionados"
          class="mt-2"
          :colunas="tabela.colunas"
          :dados="form.contratoInstrumento"
          :mostrar-acoes="false"
          :quantidade-itens="tabela.quantidadeItens"
          :por-pagina="tabela.porPagina"
          :quantidade-paginas="tabela.quantidadePaginas"
          sem-paginacao
          :mostrar-seletor="false"
        />
      </v-tab-item>
    </v-tabs-items>

    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end mt-4">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="voltar"
      >
        {{ $t('geral.botoes.voltar') }}
      </botao-padrao>
      <botao-padrao
        outlined
        color="red"
        class="mr-2"
        @click="reprovar"
      >
        {{ $t('geral.botoes.reprovar') }}
      </botao-padrao>
      <botao-padrao @click="assinar">
        {{ $t('geral.botoes.assinar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import helpers from '@common/utils/helpers';
import ParticipanteService from '@common/services/cadastros/ParticipanteService';
import { InputContrato, InputCliente } from '@components/inputs';
import { ContratoModel } from '@common/models/cadastros/ContratoModel';
import ContratoService from '@common/services/cadastros/ContratoService';
export default {
  components: {
    InputContrato,
    InputCliente,
  },
  props: ['id'],
  data() {
    return {
      indiceAba: 0,
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'codigo',
            text: this.$t('modulos.contrato.formulario.instrumento'),
          },
          {
            value: 'frequenciaCalibracaoEmMeses',
            text: this.$t('modulos.contrato.formulario.frequencia'),
          },
          {
            value: 'tipoCalibracaoNome',
            text: this.$t('modulos.contrato.formulario.tipo_servico'),
          },
          {
            value: 'localExecucaoNome',
            text: this.$t('modulos.contrato.formulario.local_execucao'),
          },
          {
            value: 'prazoEntrega',
            text: this.$t('modulos.contrato.formulario.prazo_entrega'),
          },
        ],
      },
      optionsInputMoney: {
        locale: 'pt-BR',
        prefix: '',
        suffix: '',
        length: 11,
        precision: 2,
      },
      form: {},
      opcoes: {
        situacaoContrato: helpers.StatusOrcamentoEnum,
        tipoContrato: helpers.TipoContratoEnum,
        tipoDescontoAcrescimo: helpers.TipoDescontoAcrescimoEnum,
        aosCuidadosDe: [],
        contato: [],
        localExecucao: [],
        condicaoPagamento: [],
        diasColeta: helpers.DiasColetaEnum,
        booleano: helpers.BoleanoEnum,
        formaSolicitacaoOrcamento: helpers.FormaSolicitacaoOrcamentoEnum,
      },
    };
  },
  mounted() {
    this.buscar();
  },
  methods: {
    alterarStatusContrato(params) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ContratoService.alteraStatus(params)
        .then(() => {
          this.$router.push({ name: 'contrato' });
        })
        .catch((err) => {
          if (
            err.response.data.errors[0] == 'contratoComInstrumentoJaExistente'
          ) {
            this.toastAlerta(
              this.$t(`geral.erros.contrato_instrumento_existente`)
            );
            return;
          }
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.keyAtualiza++;
        });
    },
    reprovar() {
      let params = {
        id: this.form.id,
        status: 5,
        dataStatus: new Date(),
        justificativa: 'Reprovado',
        enviaEmail: false,
      };
      this.confirmarReprovarContrato(
        this.form.codigo,
        this.form.valorFinal
      ).then(() => {
        this.alterarStatusContrato(params);
      });
    },
    assinar() {
      let params = {
        id: this.form.id,
        status: 3,
        dataStatus: new Date(),
        justificativa: '',
        enviaEmail: false,
      };
      this.confirmarAssinarContrato(
        this.form.codigo,
        this.form.valorFinal
      ).then(() => {
        this.alterarStatusContrato(params);
      });
    },
    voltar() {
      this.$router.push({ name: 'contrato' });
    },
    buscar() {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ContratoService.buscar(this.$route.params.id)
        .then(async (res) => {
          this.form = new ContratoModel(res.data);
          this.form.participante = res.data.participante?.nome;
          this.form.participanteId = res.data.participante?.id;
          this.form.participanteContatoId = res.data.participanteContato?.id;
          this.form.participanteContaId = localStorage.getItem(
            'participanteLogadoId'
          );
          this.form.valorFinal = res.data.valorFinal;

          this.form.contratoServicoManutencao = [];
          if (res.data.contratoServicoManutencao.length) {
            res.data.contratoServicoManutencao.map((item) => {
              this.form.contratoServicoManutencao.push({
                tipoInstrumentoId: item.tipoInstrumento.id,
                modeloInstrumentoId: item.modeloInstrumento?.id,
                servicoId: item.servico.id,
                servicoDescricao: item.servicoDescricao,
                valor: item.valor,
                tipoInstrumentoNome: item.tipoInstrumento.nome,
                modeloInstrumentoNome: item.modeloInstrumento?.nome,
                servicoValor: item.valor,

                servicoCodigo: item.codigoServico,
              });
            });
          }

          this.form.contratoPeca = [];
          if (res.data.contratoPeca.length) {
            res.data.contratoPeca.map((item) => {
              this.form.contratoPeca.push({
                id: item.id,
                percentualDesconto: item.percentualDesconto,
                tipoInstrumentoId: item.tipoInstrumento.id,
                modeloInstrumentoId: item.modeloInstrumento?.id,
                itemId: item.item.id,
                itemDescricao: item.itemDescricao,
                tipoInstrumentoNome: item.tipoInstrumento.nome,
                modeloInstrumentoNome: item.modeloInstrumento?.nome,
                valorVenda: item.valor,
                valorVendaComDesconto: item.valorComDesconto,

                itemCodigo: item.item.codigo,
              });
            });
          }

          this.form.contratoInstrumento.map((item) => {
            item.codigo = item.instrumento?.codigo;
            item.instrumentoId = item.instrumento?.id;
            item.tipoCalibracaoId = item.tipoCalibracao?.id;
            item.tipoCalibracaoNome = item.tipoCalibracao?.nome;
            item.localExecucaoId = item.localExecucao?.id;
            item.localExecucaoNome = item.localExecucao?.nome;
          });

          this.form.contratoInstrumento.forEach((item) => {
            if (item.contratoInstrumentoServicoCalibracao?.length) {
              item.contratoInstrumentoServicoCalibracao.map((itemDetalhe) => {
                itemDetalhe.servicoId = itemDetalhe.servico?.id;
                itemDetalhe.faixa = itemDetalhe.servico?.faixa
                itemDetalhe.servicoCodigo = itemDetalhe.servico?.codigo;
                itemDetalhe.valorServico = itemDetalhe.valorUnitarioServico;
              });
            }
          });
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.valido = true;
        });
    },
    listaContatos(id) {
      this.loadingContato = true;
      ParticipanteService.buscaParticipantesContatosPorId(id)
        .then((res) => {
          if (res?.data) {
            res?.data?.map((item) => {
              this.opcoes.contato.push({
                text: item.nome,
                value: item.id,
              });
            });
          } else this.opcoes.contato = [];
        })
        .finally(() => {
          this.loadingContato = false;
        });
    },
  },
};
</script>
